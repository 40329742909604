<template>
  <div class="login-box">
    <el-card class="box-card">
      <div slot="header" class="flex flex-h-center">
        <span>{{ flag == 1 ? $t("找回密码") : $t("手机注册") }}</span>
      </div>
      <el-form ref="form" :model="form" label-width="0">
        <el-form-item>
          <el-input :placeholder="form.phone_type == '+61' ? $t('请输入手机号码,比如: 0451507793') : $t('请输入手机号码')"
            v-model="form.phone" class="input-with-select">
            <el-select @change="saveType" v-model="form.phone_type" slot="prepend" placeholder="">
              <el-option :label="'+86（' + $t('中国') + '）'" value="+86"></el-option>
              <el-option :label="'+61（' + $t('澳大利亚') + '）'" value="+61"></el-option>
              <el-option :label="'+62（' + $t('印度尼西亚') + '）'" value="+62"></el-option>
              <el-option :label="'+60（' + $t('马来西亚') + '）'" value="+60"></el-option>
              <el-option :label="'+91（' + $t('印度') + '）'" value="+91"></el-option>
              <el-option :label="'+63（' + $t('菲律宾') + '）'" value="+63"></el-option>
              <el-option :label="'+1（' + $t('加拿大') + '）'" value="+1"></el-option>
              <el-option :label="'+64（' + $t('新西兰') + '）'" value="+64"></el-option>
              <el-option :label="'+886（' + $t('台湾') + '）'" value="+886"></el-option>
              <el-option :label="'+852（' + $t('香港') + '）'" value="+852"></el-option>
              <el-option :label="'+853（' + $t('澳门') + '）'" value="+853"></el-option>
              <el-option :label="'+44（' + $t('英国') + '）'" value="+44"></el-option>
              <el-option :label="'+81（' + $t('日本') + '）'" value="+81"></el-option>
              <el-option :label="'+82（' + $t('韩国') + '）'" value="+82"></el-option>
              <el-option :label="'+001（' + $t('美国') + '）'" value="+001"></el-option>
              <el-option :label="'+65（' + $t('新加坡') + '）'" value="+65"></el-option>
              <el-option :label="'+66（' + $t('泰国') + '）'" value="+66"></el-option>
              <el-option :label="'+84（' + $t('越南') + '）'" value="+84"></el-option>
            </el-select>
          </el-input>
          <div v-if="form.phone_type == '+886'" class="tip">*{{ $t('不需要输入0即可加入') }}</div>
        </el-form-item>



        <el-form-item>
          <el-input :type="flag1 ? 'password' : 'text'" v-model="form.password" :placeholder="$t('请输入8-20位密码')">
            <i slot="suffix" :style="{ 'color': flag1 ? '' : '#E6A23C' }" @click="flag1 = !flag1"
              class="el-icon-view pointer margin-r" style="font-size: 18px;"></i>
          </el-input>
        </el-form-item>

        <el-form-item>
          <el-input :type="flag2 ? 'password' : 'text'" v-model="password" :placeholder="$t('确认密码')">
            <i slot="suffix" :style="{ 'color': flag2 ? '' : '#E6A23C' }" @click="flag2 = !flag2"
              class="el-icon-view pointer margin-r" style="font-size: 18px;"></i>
          </el-input>
        </el-form-item>


        <el-form-item>
          <div class="flex flex-between">
            <el-input v-model="form.code" :placeholder="$t('请输入验证码')"></el-input>
            <el-button type="primary" @click="sendCode" class="btn">{{ second == 0 ? $t('获取验证码') : second + '秒'
              }}</el-button>
          </div>
        </el-form-item>


        <el-form-item>
          <!-- <div class="st">点击注册代表您已同意 <a class="st1 ul" href="#">《海澳用户协议》</a></div> -->
          <div class="st">{{ $t('注：为避免数据丢失，请使用微信或邮箱登录过的用户，继续使用原方式直接登录并绑定手机号。') }}</div>
        </el-form-item>


        <el-form-item>
          <el-button class="block" round @click="submit" type="primary">{{ flag == 1 ? $t("完 成") : $t("注 册")
            }}</el-button>

        </el-form-item>
        <div class="flex flex-h-center"><span class="st1"> {{ $t('已有账号') }}! </span><a href="/login"
            class="margin-l st">
            {{ $t('立即登录') }}</a>
        </div>

      </el-form>




    </el-card>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      flag1: true,
      flag2: true,
      tab: "phone",
      form: {
        phone_type: "+86",
        phone: '',
        password: '',
        code: ''
      },
      password: "",
      second: 0,
      flag: 0,
    }
  },

  created() {
    this.form.phone_type = localStorage.getItem("phone_type") || "+86";
    if (this.$route.query.flag) {
      this.flag = this.$route.query.flag;
    }
  },
  methods: {
    saveType(val) {
      localStorage.setItem("phone_type", val);
    },

    sendCode() {
      if (this.form.phone && this.second == 0) {

        // if (this.form.phone_type == '+61' && this.form.phone.startsWith('0')) {
        //   this.$message('澳洲手机号注册时,前面不能有0');
        //   return
        // }
        this.$http.post("/api/sms", { phone: this.form.phone, phone_type: this.form.phone_type }).then(() => {
          this.timer(120);
        })
      }
    },

    submit() {
      if (this.form.phone_type == '+61' && this.form.phone.startsWith('0')) {
        this.$message('澳洲手机号注册时,前面不能有0');
      } else if (this.form.phone.length == 0) {
        this.$message({
          message: this.$t('手机号码错误') + '!',
          type: 'primary'
        });
      } else if (this.form.password.length < 6) {
        this.$message({
          message: this.$t('密码最少6位') + '!',
          type: 'primary'
        });
      } else if (this.form.password != this.password) {
        this.$message({
          message: this.$t('确认密码错误') + '!',
          type: 'primary'
        });
      } else if (this.form.code.length != 6) {
        this.$message({
          message: this.$t('验证码错误') + '!',
          type: 'primary'
        });
      } else {
        this.$http.post("/api/regist", this.form).then(() => {
          this.$message({
            message: this.$t('注册成功') + '!',
            type: 'success'
          });
          this.$router.push("/login");
        })
      }
    },

    timer(second) {
      if (second) this.second = second;
      let that = this;
      setTimeout(() => {
        if (that.second > 0) {
          that.second--;
          that.timer();
        }
      }, 1000);
    },
  },
};
</script>

<style scoped>
.login-box {
  height: 600px;
  width: 500px;
  margin: 80px auto;
}

.el-select {
  width: 160px;
}

.input-with-select>>>.el-input-group__prepend {
  background-color: #fff;
  color: #E6A23C;
}

.el-form-item {
  margin-top: 30px;
}

.btn {
  margin-left: 20px;
}
.tip{
  color: red;
  margin-bottom: -20px;
}
</style>
